import React from 'react'
import './Header.scss'
import { motion } from 'framer-motion'
import { images } from '../../constants'
import { AppWrap } from '../../wrapper'

const scaleVariants = {
  whileInView: {
    sclae: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}
const Header = () => {

  return (
    <div className='app__header app__flex'>
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className='app__header-info'
      >
        <div className='app__header-badge'>
          
          <div className='tag-cmp app__flex header-small'>
            <div className='bio-text'>
              <p className='p-intro'>Hi, I'm Elisabeth</p>
              <p className='p-job'>App Developer II at <a className='p-job-link' href='https://www.abtglobal.com/' target='_blank'>Abt Global</a> & the occasional freelancer</p>
              {/* <p className='p-job'>App Developer II @ Abt Global & the occasional freelancer</p> */}
          
              <p className='p-bio spacing'>
              What fuels me is my genuine love for programming and my endless stream of app ideas. 
              I’m driven by the challenge of bringing those ideas to life, and committed to the work to get there.   
              I’m particularly passionate about building apps that make a meaningful social and environmental impact. 
              I recognize the privilege I’ve had in pursuing these opportunities, 
              and I’m deeply committed to using them to create something truly impactful.
              </p>
              <p className='p-bio spacing'>
                "Those who have the privilege to know, have the duty to act." – Albert Einstein
              </p>
            </div>
            <div className="app__header-img"  >
              <img src={images.profile} alt="profile_bg" />
            </div> 
          </div>

           
        </div>
        <div className="location-cmp">
            <motion.div className="location-badge" whileHover={{ scale: 1.1 }}>
              <p>Located in New York</p>
              <img src={images.globe} alt="Location Icon" className="globe-icon" />
            </motion.div>
          </div>
        
      </motion.div>
 
      {/* Location Badge */}
      

      {/* <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className='app__header-circles'
      >
        {[images.git, images.apex, images.redux].map((circle, index) => (
          <div className='circle-cmp app__flex' key={`circle-${index}`}>
            <img src={circle} alt='profile_bg' />
          </div>
        ))}
      </motion.div> */}
    </div>
  )
}

export default AppWrap(Header, 'home')